<template>
  <div>
    <!-- banner -->
    <div class="banner" :style="cover">
      <h1 class="banner-title">个人分类</h1>
    </div>
    <!-- 分类列表 -->
    <div class="article-list-wrapper">
      <el-empty v-if="categoryList.length === 0" description="暂无数据..."></el-empty>
      <v-row>
        <v-col md="4" cols="12" v-for="item of categoryList" :key="item.categoryId">
          <!-- 分类 -->
          <v-card class="animated zoomIn article-item-card">
            <div class="article-item-cover">
              <router-link :to="'/categories/' + item.categoryId" tag="a" target="_blank">
                <!-- 缩略图 -->
                <v-img class="on-hover" width="100%" height="100%" :src="item.cover"/>
              </router-link>
            </div>
            <div class="article-item-info">
              <!-- 分类名称-->
              <div>
                <router-link :to="'/categories/' + item.categoryId" tag="a" target="_blank">
                  {{ item.categoryName }}
                </router-link>
              </div>
              <div style="margin-top:0.375rem">
                <!-- 创建时间 -->
                <v-icon size="20">mdi-clock-outline</v-icon>
                {{ item.createTime | date }} | 收录<strong>{{ item.articleCount }}</strong>篇文章
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- 分页按钮 -->
      <v-pagination v-if="count > 0"
                    color="#00C4B6"
                    v-model="current"
                    :length="Math.ceil(count / param.pageSize)"
                    total-visible="7" style="margin-top: 20px;"
      />

    </div>
  </div>
</template>

<script>
import { getCategoryByUserId } from "@/api/article";
import { userinfo } from "@/api/user";
export default {
  created() {
    this.fetchUserCategory();
    this.fetchUserInfo();
  },
  data: function() {
    return {
      categoryList: [],
      count: 0,
      param: {
        pageNo: 1,
        pageSize: 9,
        userId: ""
      },
      current: 1,
      userHomepageCover: ""
    }
  },
  methods: {
    // 全部分类
    fetchUserCategory() {
      this.param.userId = this.$route.params.userId;
      this.param.pageNo = this.current;
      getCategoryByUserId(this.param).then(res => {
        this.categoryList = res.data.list;
        this.count = res.data.total;
      })
    },

    // 获取用户信息
    fetchUserInfo() {
      userinfo(this.$route.params.userId).then(res => {
        if (res.code === 200) {
          this.userHomepageCover = res.data.homepageCover;
        }
      })
    },
  },

  computed: {
    cover() {
      return "background: url(" + this.userHomepageCover + ") center center / cover no-repeat";
    }
  },

  watch: {
    current(value) {
      this.current = value;
      this.fetchUserCategory();
    }
  }

};
</script>

<style scoped>
@media (min-width: 760px) {
  .article-list-wrapper {
    max-width: 1106px;
    margin: 370px auto 1rem auto;
  }
  .article-item-card:hover {
    transition: all 0.3s;
    box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  }
  .article-item-card:not(:hover) {
    transition: all 0.3s;
  }
  .article-item-card:hover .on-hover {
    transition: all 0.6s;
    transform: scale(1.1);
  }
  .article-item-card:not(:hover) .on-hover {
    transition: all 0.6s;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
    font-size: 15px;
  }
}
@media (max-width: 759px) {
  .article-list-wrapper {
    margin-top: 230px;
    padding: 0 12px;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
  }
}
.article-item-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
.article-item-card a {
  transition: all 0.3s;
}
.article-item-cover {
  height: 220px;
  overflow: hidden;
}
.article-item-card a:hover {
  color: #8e8cd8;
}
.tag-wrapper a {
  color: #fff !important;
}
.showInline {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
</style>
